import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import { softShadows, strongShadows } from "./shadows";
import typography from "./typography";

const baseOptions = {
  direction: "ltr",
  typography,
  overrides: {
    MuiSelect: {
      icon: {
        color: "#fff",
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: "0px",
        "@media (min-width: 600px)": {
          minHeight: "0px !important",
        },
      },
    },
    MuiIconButton: {
      root: {
        flex: "0 0 auto",
        color: "#fff",
        width: "40px",
        height: "40px",
        overflow: "visible",
        borderRadius: "50%",

        "@media (max-width: 767px)": {
          width: "40px",
          height: "40px",
        },
        "&.Mui-disabled": {
          "& .MuiSvgIcon-root": {
            color: "rgba(0, 0, 0, 0.26)",
          },
        },
      },
    },
    MuiDialogActions: {
      root: {
        marginRight: "14px",
      },
    },
    MuiDialogContent: {
      root: {
        overflowY: "none",
      },
    },
    MuiMenu: {
      list: {
        padding: "10px ",
      },
    },
    MuiFormControl: {
      marginDense: {
        marginTop: "8px !important",
        marginBottom: "5px !important",
        /* height: 33px; */
      },
      root: {
        border: "0",
        width: "100%",
        margin: "0",
        display: "inline-flex",
        padding: "0",
        position: "relative",
        minWidth: "0",
        flexDirection: "column",
        verticalAlign: "top",
        marginTop: "8px",
      },
    },
    MuiCheckbox: {
      root: {
        width: "10px",
        height: "10px",
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: "hidden",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
        color: "white",
      },
    },
    MuiList: {
      padding: {
        paddingTop: "0px",
        paddingBottom: "8px",
      },
    },
    MuiListItem: {
      root: {
        paddingBottom: "6px",
        paddingTop: "0x",
      },
      gutters: {
        paddingLeft: "0px",
        paddingRight: "0px",
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "rgba(0,0,0,0.075)",
      },
    },
    MuiTableCell: {
      alignCenter: {
        textAlign: "left",
      },
      alignRight: {
        textAlign: "left",
      },
      root: {
        borderBottom: "none",
        padding: "12px 6px 14px 10px",
        textAlign: "left",
        fontSize: "13px",
      },
      body: {
        color: "#fff",
      },
      head: {
        color: "#fff",
        backgroundColor: "#302f35",
        fontSize: "14px",
        fontWeight: "600",
        textAlign: "center",
      },
    },
    MuiTypography: {
      colorPrimary: {
        color: "#000",
      },

      caption: {
        color: "#A7A1A1",
        fontSize: "12px",
      },
      subtitle2: {
        color: "#fff",
        lineHeight: "20px !important",
      },
    },
    MuiLink: {
      underlineHover: { color: "#747474" },
    },
    MuiTab: {
      textColorPrimary: {
        color: "#fff",
        "&.Mui-selected": {
          color: "#fff",
        },
      },
    },

    MuiButton: {
      root: { color: "#fff" },
      containedSecondary: {
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "5px",
        color: "#000",
        fontSize: "14px",
        backgroundColor: "#E9C856",
        padding: "5px 19px",
      },

      containedPrimary: {
        backgroundColor: "#E9C856",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "5px",
        color: "#000000",
        fontSize: "18px",
        fontWeight: "500",

        lineHeight: " 21px",
        padding: "10px 39px",
        marginRight: "10px",
        "&:hover": {
          backgroundColor: "#E9C856",
          color: "#000",
        },
      },
      contained: {
        borderRadius: "5px",
        color: "#000",
        fontSize: "18px",
        fontWeight: "500",

        padding: "5px 19px",

        "&:hover": {
          backgroundColor: "#E9C856",

          color: "#000",
        },
      },
      outlinedPrimary: {
        borderRadius: "5px",
        color: "#FFFFFF",
        fontSize: "16px",

        fontWeight: 600,
        padding: "5px 26px",
        border: "1px solid #E9C856",
        "&:hover": {
          backgroundColor: "#E9C856",
          color: "#000",
        },
      },
      outlinedSizeSmall: {
        padding: "6px 23px",
        fontSize: "16px",
        lineHeight: " 24px",
      },
    },
    MuiTableRow: {
      root: {
        border: "1px solid #121212",
      },
    },
    MuiPaper: {
      rounded: {
        border: "20px",
      },
      root: {
        backgroundColor: "#302F35",
        color: "#fff",
      },
      outlined: {
        backgroundColor: "#2A292E",
        border: "1px solid #797979",
      },
    },
    PrivateTabIndicator: {
      colorPrimary: {
        backgroundColor: "#FF2626",
      },
    },
    MuiDropzoneArea: {
      root: {
        backgroundColor: "transparent",
        border: "1px dashed #5d5656 !important",
        minHeight: "170px !important",
      },
    },

    MuiFormLabel: {
      root: {
        color: "#fff",
        marginTop: "0",
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: "0px",
        marginRight: "0px",
        fontSize: "12px !important",
      },
    },
    MuiInputBase: {
      input: {
        "&.Mui-disabled": {
          border: "1px solid #312e2e",
          borderRadius: "4px",
        },
        color: "#fff",
      },
      root: {
        fontSize: "13px",
      },
    },
    MuiPopover: {
      paper: {
        marginTop: "44px",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: { backgroundColor: "transparent !important" },

      dayLabel: {
        color: "#fff",
      },
    },
    MuiPickersDay: {
      day: {
        color: "#fff",
      },
    },
    MuiSelect: {
      iconOutlined: {
        color: "#fff",
        right: "10px",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#fff",
      },
    },
    MuiAlert: {
      standardError: {
        color: "none",
        backgroundColor: "none",
      },
    },

    MuiPagination: {
      ul: {
        background: "none !important",
        height: "41px !important",
        width: "auto !important",
      },
    },
  },
};

const themesOptions = [
  {
    name: "LIGHT",
    overrides: {
      // MuiInputBase: {
      //   input: {

      //     border: "0",

      //     "&::placeholder": {
      //       opacity: 1,
      //       color: "#fff",
      //     },
      //   },

      // },

      MuiOutlinedInput: {
        root: {
          "& ::-webkit-input-placeholder": {
            color: "#fff !important",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #E9C856",
            borderColor: "#E9C856",
          },
        },
        notchedOutline: {
          border: "1px solid #ececec",
          borderColor: "#ececec",
        },
      },
    },
    palette: {
      type: "light",
      action: {
        active: colors.blueGrey[600],
      },
      background: {
        default: colors.common.white,
        dark: "#f4f6f8",
        paper: colors.common.white,
      },
      primary: {
        main: "#E9C856",
      },
      secondary: {
        main: "#FF9933",
      },
      tertiary: {
        main: "#ccc",
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
      },
    },
    shadows: softShadows,
  },
  {
    name: "DARK",
    palette: {
      type: "dark",
      action: {
        active: "rgba(255, 255, 255, 0.54)",
        hover: "rgba(255, 255, 255, 0.04)",
        selected: "rgba(255, 255, 255, 0.08)",
        disabled: "rgba(255, 255, 255, 0.26)",
        disabledBackground: "rgba(255, 255, 255, 0.12)",
        focus: "rgba(255, 255, 255, 0.12)",
      },
      background: {
        default: "#282C34",
        dark: "#1c2025",
        paper: "#282C34",
      },
      primary: {
        main: "#1f73b7",
      },
      secondary: {
        main: "#1f73b7",
      },
      warning: {
        main: "#BC211D",
      },
      error: {
        main: "#B33A3A",
      },
      text: {
        primary: "#e6e5e8",
        secondary: "#adb0bb",
      },
    },
    shadows: strongShadows,
  },
];

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge({}, baseOptions, themeOptions, { direction: config.direction })
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
