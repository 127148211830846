import React, { useState, useEffect, useContext } from "react";
import NotificationsList from "src/component/notifications";
import {
  Grid,
  Box,
  Button,
  Typography,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import Page from "src/component/Page";
import Axios from "axios";
import ApiConfig from "src/config/APICongig";
import { toast } from "react-toastify";
import NoDataFound from "src/DataNotFound";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import { useLocation } from "react-router-dom";
import { AuthContext } from "src/context/Auth";
import { useHistory, Link as RouterLink } from "react-router-dom";
export default function Notifications({ popUp }) {
  const [notificationList, setNotificationList] = useState([]);
  console.log("notificationList", notificationList);
  const auth = useContext(AuthContext);
  const userdata = auth.userData ? auth.userData : "";
  const [open, setopen] = useState(false);
  const [count, setCount] = useState([]);
  const history = useHistory();
  useEffect(() => {
    if (!window.localStorage.getItem("token")) {
      history.push("/login");
    }
  }, [window.localStorage.getItem("token")]);

  const [isLoading, setIsLoading] = useState(false);
  const getNotifictionList = async () => {
    setIsLoading(true);
    try {
      const res = await Axios.get(ApiConfig.getNotification, {
        headers: {
          authorization: `Bearer ${window.localStorage.getItem("token")}`,
          role: "USER",
        },
        params: {
          fkUserId: userdata?.userId,
        },
      });

      if (res.data.status === 1618) {
        setCount(res.data.data.filter((data) => !data.isSeen));
        setNotificationList(res.data.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      toast.error(err.response.data.responseMessage);
      console.error(err.response);
      setIsLoading(false);
    }
  };
  const clearNotifictionList = async (id) => {
    try {
      setIsLoading(true);
      const res = await Axios({
        method: "POST",
        url: ApiConfig.clearNotification,
        headers: {
          authorization: `Bearer ${window.localStorage.getItem("loginToken")}`,
          userId: id,
        },
      });
      if (res.data.status === 200) {
        toast.success(res.data.message);
        getNotifictionList();
        setNotificationList([]);
        // setNotificationList("null");
      } else {
        toast.warn(res.data.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("ERROR", error);
      toast.error("something went wrong");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userdata?.userId) {
      getNotifictionList();
    }
  }, [userdata?.userId]);
  useEffect(() => {
    setNotificationList([]);
  }, []);

  return (
    <Page title="Notifications">
      <Box className="classes.notificationHeading">
        <Box mb={3}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {popUp ? (
              ""
            ) : (
              <>
                <Box display="flex" className="notificationbox">
                  <Typography
                    variant="h5"
                    pt={2}
                    style={{ marginTop: "20px", fontSize: "30px" }}
                  >
                    Notifications
                  </Typography>
                </Box>
              </>
            )}
            {popUp
              ? ""
              : notificationList &&
                notificationList.length !== 0 && (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginBottom: "-24px",
                    }}
                  >
                    <Button onClick={() => setopen(true)}>clear all</Button>
                  </Box>
                )}
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {popUp ? (
              <Box display="flex" className="notificationbox">
                <Typography variant="h3" pt={2}>
                  Notifications
                </Typography>
              </Box>
            ) : (
              ""
            )}

            {popUp && notificationList && notificationList.length !== 0 && (
              <Box
                pt={1}
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
                textAlign="right"
              >
                <Button onClick={() => setopen(true)}>clear all</Button>
                &nbsp;
                <Button
                  onClick={() => {
                    history.push({
                      pathname: "/notifications",
                    });
                  }}
                >
                  View all
                </Button>
              </Box>
            )}
          </Box>
        </Box>
        {isLoading ? (
          <ButtonCircularProgress />
        ) : (
          <Grid container spacing={4} sm={12} style={{ margin: "0" }}>
            {popUp
              ? notificationList.slice(0, 4).map((data, i) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      key={i}
                      style={{ padding: "15px 0" }}
                    >
                      <NotificationsList popUp={popUp} data={data} index={i} />
                    </Grid>
                  );
                })
              : notificationList &&
                notificationList.map((data, i) => {
                  return (
                    <Grid item xs={12} sm={12} md={12} key={i}>
                      <NotificationsList popUp={popUp} data={data} index={i} />
                    </Grid>
                  );
                })}
          </Grid>
        )}
        {popUp ? (
          <>
            {" "}
            {!isLoading && notificationList && notificationList.length === 0 && (
              <Box style={{}}>
                <Typography variant="h3" style={{ fontSize: "15px" }}>
                  No Data Found
                </Typography>
              </Box>
            )}
          </>
        ) : (
          ""
        )}
      </Box>
      <div>
        <Dialog
          open={open}
          onClick={() => setopen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle id="alert-dialog-title">
            Clear All Notification
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you want to sure clear notification
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => setopen(false)}
              autoFocus
              style={{ padding: "1px 20px" }}
            >
              No
            </Button>
            <Button
              variant="containedPrimary"
              style={{ padding: "5px 20px", textTransform: "capitalize" }}
              onClick={() => clearNotifictionList(userdata?.userId)}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {popUp ? (
        ""
      ) : (
        <>
          {!isLoading && notificationList && notificationList.length === 0 && (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                width: "center",
                marginTop: "20px",
              }}
            >
              <Typography variant="h3">No Data Found</Typography>
            </Box>
          )}
        </>
      )}
    </Page>
  );
}
