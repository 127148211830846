/* eslint-disable no-use-before-define */
import React, { useContext, useEffect, useRef } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
// import { Link, Component } from "react-router-dom";
// import { useHistory } from 'react-router-dom';

import PropTypes from "prop-types";
import {
  Box,
  Button,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Logo from "src/component/Logo";
import {
  FaTachometerAlt,
  FaUniversity,
  FaWallet,
  FaClipboardCheck,
  FaMoneyBillAlt,
  FaPhoneSquareAlt,
  FaHistory,
} from "react-icons/fa";
import { GiToken, GiTrade } from "react-icons/gi";
import { IoIosPaper } from "react-icons/io";

import NavItem from "./NavItem";
import { AuthContext } from "src/context/Auth";
import ChartList from "./ChartList";

const sections = [
  {
    items: [
      {
        title: "Dashboard",
        icon: FaTachometerAlt,
        href: "/dashboard",
      },
      {
        title: "Transaction History",
        icon: FaHistory,
        href: "/transaction-history",
      },
      // {
      //   title: "Trade",
      //   icon: GiTrade,
      //   href: "/trade",
      // },
      // {
      //   title: "Bank Details",
      //   icon: FaUniversity,
      //   href: "/bank-details",
      // },
      {
        title: "My Wallet",
        icon: FaWallet,
        href: "/my-wallet",
      },
      {
        title: "KYC",
        icon: FaClipboardCheck,
        href: "/kyc",
      },
      {
        title: "White Paper",
        icon: IoIosPaper,
        href: "/white-paper",
      },
      {
        title: "Buy Token",
        icon: GiToken,
        href: "/token",
      },
      // {
      //   title: "Currency",
      //   icon: FaMoneyBillAlt,
      //   href: "/currency",
      // },
      // {
      //   title: "Contact Us",
      //   icon: FaPhoneSquareAlt,
      //   href: "/contact-us",
      // },
    ],
  },
];
const sectionsBelow = [
  {
    items: [
      {
        title: "Terms and Conditions",
        //icon: PieChartIcon,
        href: "/terms",
      },
      {
        title: "Privacy Policy",
        //icon: PieChartIcon,
        href: "/privacy-policy",
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: "#302F35",
    boxShadow: " 0 0.1rem 0.7rem rgb(0 0 0 / 10%)",
  },
  desktopDrawer: {
    width: 256,
    top: 0,
    height: "100%",
    background: "#302F35",
    boxShadow: " 0 0.1rem 0.7rem rgb(0 0 0 / 10%)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();

  const location = useLocation();
  const auth = useContext(AuthContext);

  const history = useHistory();
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Hidden mdDown>
        <Box
          padding={0}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Logo
            onClick={() => history.push("/")}
            alt="Logo"
            style={{
              paddingTop: "6px",

              cursor: "pointer",
              width: "90px",
              cursor: "pointer",
            }}
          />
        </Box>
      </Hidden>
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box my={3}>
          {sections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
          <Box style={{ height: "100px !important" }}>
            <ChartList height="38%" />
          </Box>
        </Box>

        <Box className="side_nev_Bottom">
          {sectionsBelow.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {section.items.map((itemList, i) => {
                return (
                  <Button
                    fullWidth
                    key={i}
                    style={{
                      justifyContent: "start",
                      paddingLeft: 25,
                      borderRadius: 0,
                      textTransform: "capitalize",
                    }}
                    onClick={() => history.push(itemList.href)}
                  >
                    {itemList.title}
                  </Button>
                );
              })}

              {/* {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })} */}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
